<template>
    <div class="h-100">
        <portal to="layout-default-header">
            <header-index :title="title">
                <slot name="header" />

                <input-search
                    v-model="members.searchQuery"
                    class="mr-3"
                />

                <template #create-new>
                    <b-btn
                        v-b-modal="modalInviteId"
                        variant="dark"
                        class="btn-min-width"
                    >
                        {{ inviteButton }}
                    </b-btn>

                    <users-modal-invite-users
                        :id="modalInviteId"
                        :title="$t('clients.actions.inviteClients')"
                        :organisation="company"
                        :role="role"
                        :hide-organisation-select="!!company"
                        :hide-role-select="!!company"
                        @invitations-created="onInvitationsCreated()"
                    />
                </template>
            </header-index>
        </portal>

        <div
            v-if="noMembers"
            class="h-100 d-flex flex-column justify-content-center align-items-center"
        >
            <h5>{{ $t('clients.messages.inviteFirstClients') }}</h5>

            <b-link
                v-b-modal="modalInviteId"
                class="text-primary mt-2"
            >
                {{ $t('clients.actions.inviteClients') }}
            </b-link>
        </div>

        <members-table
            v-show="!noMembers"
            ref="membersTable"
            :collection="members"
            :include-fields="includeFields"
        />
    </div>
</template>

<script>
import UsersModalInviteUsers from '@/components/users/ModalInviteUsers';
import Role from '@/library/enumerations/Role';
import {Company} from '@/models/Company';
import Model from '@/models/vue-mc/Model';
import {User} from '@/models/User';
import {Members} from '@/models/Member';
import MembersTable from '@/components/members/Table';
import HeaderIndex from '@/components/layouts/HeaderIndex';
import InputSearch from '@/components/common/form/InputSearch';

export default {
    name: 'MembersIndex',
    components: {UsersModalInviteUsers, InputSearch, MembersTable, HeaderIndex},
    props: {
        parent: {
            type: Model,
            required: false,
            default: undefined,
        },
        company: {
            type: Model,
            required: false,
            default: undefined,
        },
        title: {
            type: String,
            required: true,
        },
        role: {
            type: Number,
            required: false,
            default: undefined,
        },
        includeFields: {
            type: Array,
            default: undefined,
        },
    },
    data: function() {
        return {
            /**
             * A collection of members.
             */
            members: new Members([], {
                routePrefix: this.parent
                    ? this.parent.getFetchURL()
                    : undefined,
            }, {
                role: this.role,
            }),
            modalInviteId: 'modal-invite-members',
        };
    },
    computed: {
        inviteButton() {
            const role = typeof this.role === 'number' ? this.role : 'USER';
            const translatedRole = Role.$t(role, 'plural').toLowerCase();

            return this.$t('users.actions.invite', {type: translatedRole});
        },
        noMembers() {
            return !this.members.loading && !this.members.searchQuery && this.members.isEmpty();
        },
    },
    watch: {
        role(value) {
            this.members.role = value;

            this.members.clear();

            this.members.fetch();
        },
    },
    methods: {
        onInvitationsCreated() {
            // Once new invitations created, instruct <members-table> to fetch
            // members from API.
            this.$refs.membersTable
                .resetCollection()
                .fetchCollectionUntilScrollable();
        },
    },
};
</script>
