<template>
    <b-row
        align-h="between"
        align-v="center"
    >
        <b-col cols="auto">
            <h1 class="text-capitalize">
                {{ title }}
            </h1>
        </b-col>

        <b-col cols="auto">
            <div class="d-flex">
                <slot />

                <slot name="create-new">
                    <btn-square
                        variant="primary"
                        @click.native="$emit('click-create')"
                    >
                        <fa
                            icon="plus"
                            size="xs"
                        />
                    </btn-square>
                </slot>
            </div>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'HeaderIndex',
    props: {
        title: {
            type: String,
            default: '',
        },
    },
};
</script>
