<template>
    <members-index
        :company="company"
        :include-fields="fields"
        :parent="user"
        :role="role"
        :title="$t('users.members.index.nav')"
    />
</template>

<script>
import Role from '@/library/enumerations/Role';
import {User} from '@/models/User';
import MembersIndex from '@/pages/shared/Members';

export default {
    name: 'UserMembersIndex',
    components: {MembersIndex},
    props: {
        user: {
            type: User,
            required: true,
        },
    },
    data: function() {
        return {
            company: this.user.company
                ? this.user.company.clone()
                : undefined,
            fields: [
                'name',
                'groupName',
                'relatedProgramsCount',
                'relatedMediaCount',
                'createdAt',
                'status',
                'actions',
            ],
            role: Role.CLIENT,
        };
    },
};
</script>
